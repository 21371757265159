import axios from "axios";
import { useState } from "react";

const Scripture = ()=>{
  const [content, setScripture] = useState("");
  const handleSubmit=async(e)=>{
    e.preventDefault();
    await axios.post("https://backend.zuruorg.site/api/v1/admin/add-scripture", {content})
    .then((res)=>{if (res.status===200){
      window.location.reload();

    }})
  }
  return(
    <form onSubmit={handleSubmit}>
      <h2>Add Sermon message</h2>
      <label>Message: </label>
      <input type="text" name="message" value={content} onChange={(e)=>setScripture(e.target.value)}></input>
      <button type="submit">Add</button>
    </form>
  );
}

export default Scripture;