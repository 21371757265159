import React from "react";


const Landing =()=>{
  return(
    <>
     
      <header>
          <article>
          
             <div className="start">
              <a href="/login" class="btn btn_3" >Get Started</a>
               
             </div>
             
          </article>
          
      </header>
      </>
  );
}

export default Landing;
      
    
    