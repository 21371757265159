import axios from "axios";
import { useState, useEffect } from "react";
import { useParams  } from "react-router-dom";

  const Addvideo = () => {
    const [grades, setGrades] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    
  const [name, setName] = useState("");
  const [video, setVideo] = useState("");
  const [videoid, setVideoid] = useState("");
  const [topicExistsError, setTopicExistsError] = useState(false);
  const [image, setImage] = useState("");
  
    const fetchSubjects = async (gradeId) => {
      try {
        const res = await axios.get(`https://backend.zuruorg.site/api/v1/auth/getsubjects/${gradeId}`);
        if (res.status === 200) {
          setSubjects(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      const fetchGrades = async () => {
        try {
          const res = await axios.get("https://backend.zuruorg.site/api/v1/auth/getgrades");
          if (res.status === 200) {
            setGrades(res.data);
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchGrades();
    }, []);
  
    useEffect(() => {
      if (selectedGrade) {
        fetchSubjects(selectedGrade);
      }
    }, [selectedGrade]);
    
    // const handleUpload=async(e)=>{
    //   try {
    //     const file = e.target.files[0];
    //     if (!file) {
    //       return alert("File doesn't exist!");
    //     }else{
    //       let formData = new FormData();
    //       formData.append('file', file);
    //       const res= await axios.post('http://localhost:8080/api/v1/admin/img-upload', formData);
    //       if (res.status === 200) {
    //         setImage(res.data.imgURL);
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    const handleSubmit = async (e) => {
    e.preventDefault();

    // Fetch lessons for the selected subject
    try {
      const res = await axios.get(`https://backend.zuruorg.site/api/v1/admin/get-lessons/${selectedSubject}`);
      if (res.status === 200) {
        // Check if the topic already exists for the selected subject
        const topicExists = res.data.some(lesson => lesson.name === name);
        if (topicExists) {
          setTopicExistsError(true);
          return;
        } else {
          setTopicExistsError(false);
        }
      }
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }

    try {
      const res = await axios.post("https://backend.zuruorg.site/api/v1/admin/add-lesson", { name, videoid,selectedGrade, selectedSubject });
      console.log("Response:", res);

      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };
    return (
 
    <section>
      <form onSubmit={handleSubmit}>
        <div className="title">
          <h2>Control Panel</h2>
          <p>For Zuru team only</p>
        </div>
      
          <div className="passport">
            
            <div className="custom-file">
              <label>Topic: </label>
              <input type="text" name="topic" value={name} onChange={(e)=>{setName(e.target.value)}}/>
              
              <label>Grade: </label>
              <br/>
<select className="form-group col-md-6" name="grade" id="grade-select" required size={1}
value={selectedGrade}
onChange={(e) => setSelectedGrade(e.target.value)}
>

              <option>Please select grade</option>
                     {  
                        grades.map(grade=>(
                          <option value={grade._id} key={grade._id}>{grade.grade}</option>
                        ))
                      }
              </select>
              <br/>
              <label>Subject:</label>
              <br/>
<select className="form-group col-md-6" name="grade" id="grade-select" required size={1}
value={selectedSubject}
onChange={(e) => setSelectedSubject(e.target.value)}
>
          <option>Please select subject</option>
               {  
                        subjects.map(subject=>(
                          <option value={subject._id} key={subject._id}>{subject.name}</option>
                        ))
                      }
              </select>
              <br/>
              {/* <label>Image:Please use a file that best describes this lesson </label>
      <input type="file" onChange={handleUpload}/> */}

      <h3>Add topic Video</h3>
  
          <h6>Please upload only one video</h6>
          <h4>MP4</h4> 
            <a href="#" className="don_icon"><i className="ion-android-done"></i></a> 
              <label>VideoId: </label>
              <input type="text" name="topic" value={videoid} onChange={(e)=>{setVideoid(e.target.value)}}/>
              {/* <input type="file" className="custom-file-input" id="upload" onChange={handleUpload}/>
              <label className="custom-file-label" htmlFor="upload"><i className="ion-android-cloud-outline"></i>Choose Video</label> */}

               </div>
          </div>
          <br/>  
          <button>Submit</button>
      </form>
    </section>
  );
};

export default Addvideo;