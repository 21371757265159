import axios from "axios";
import { useState } from "react";

const Grade = ()=>{
  const [grade, setGrade] = useState("");
  const handleSubmit=async(e)=>{
    e.preventDefault();
    await axios.post("https://backend.zuruorg.site/api/v1/admin/add-grade", {grade})
    .then((res)=>{if (res.status===200){
      window.location.reload();

    }})
  }
  return(
    <form onSubmit={handleSubmit}>
      <h2>Add Grade</h2>
      <label>Grade: </label>
      <input type="text" name="grade" value={grade} onChange={(e)=>setGrade(e.target.value)}></input>
      <button type="submit">Add</button>
    </form>
  );
}

export default Grade;