import axios from "axios";
import { useState } from "react";


const Register=()=>{
  const [name, setUsername] = useState("");
  
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password1 === password2) {
      await axios.post("http://localhost:8000/api/auth/adminsignup", { telephone, name, password2 })
        .then((res) => {
          if (res.status === 200) {
            window.location.replace("/login")
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        })
    } else {
      setMessage("Passwords don't match!");
    }
  }
  
  return(
    <section>
        <div className="content">

           

            <div className="right">
                <div className="title">
                <img className="logo" src={require('../assets/logo.png' )}  height = "150px" width = "150px" />
                </div>
                <div className="form">
                    <form onSubmit={handleSubmit}>
                        <div className="inputbox">
                            <label>Enter Name: </label>
                            <input type="text"  autoComplete="off" placeholder="Enter Full Name" value={name} required onChange={(e)=>setUsername(e.target.value)}/>
                        </div>
                        <div className="inputbox">
                            <label>Telephone: </label>
                            <input type="tel" placeholder="Enter your Phone number" value={telephone} required onChange={(e)=>setTelephone(e.target.value)}/>
                        </div>
                        <div className="inputbox">
                            <label>Password: </label>
                            <input type="password" id="" title="Maximum of 6 . Letters and numbers" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" placeholder="Enter Password" value={password1} required onChange={(e)=>setPassword1(e.target.value)}/>
                        </div>
                        <div className="inputbox">
                            <label>Confirm Password: </label>
                            <input type="password" title="Password should match password" placeholder="Confirm the password"
                            value={password2} onChange={(e)=>setPassword2(e.target.value)} required />
                        </div>
                        <div className="create">
                            <button type="submit">Create Account</button>
                            <br/>
                       
                        </div>
                        <p>Already have an account ? <span><b><a href="/login">Log In</a></b></span></p>
                        <div className="additional">
                            
                        </div>
                    </form>
                  
                </div>
            </div>

        </div>
    </section>
  )
}

export default Register;