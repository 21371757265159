import React, {useContext, useState,useEffect,useRef} from "react";
import axios from "axios";
import "./login.css";


const Login = () =>{
 

  const [password, setPassword] = useState("");
  const [telephone, setTelephone] = useState("");
  const handleSubmit = async (e)=>{
    e.preventDefault();
    console.log(telephone);
    await axios.post("https://dev.zuruapp.site/api/v1/auth/adminlogin", {telephone, password})
    .then((res)=>{
      if (res.status === 200) {
        window.location.replace("/wMWU3MTQxZjZhZWU0ODAxYjZlOWYiLC");
      }
       
    
    })
    .catch(err=>{
      console.log(err);
    });
  }
    return(
      <section>
        <div class="content">
        <div class="right">
                <div class="title">
                    <img className="logo" src={require('../assets/landing3.png' )}  height = "200px" width = "200px" />
          
                </div>
                <div class="form">
                    <form onSubmit={handleSubmit}>
                       
                        <div class="inputbox">
                            <label>Telephone</label>
                            <input type="tel" placeholder="Enter your Email:" value={telephone} required onChange={(e)=>setTelephone(e.target.value)} />
                        </div>
                        <div class="inputbox">
                            <label>Password</label>
                            <input type="password" min = "4" placeholder="Enter your Password" event={password} required onChange={(e)=>setPassword(e.target.value)}/>
                        </div>
                        <div class="create">
                            <button type="submit">Log In</button>
                        </div>
                        <br/>
                        <p>Not registered ? <span><b><a href="/signup">Register</a></b></span></p>
                        <div class="additional">
                            
                        </div>
                    </form>
                  
                    <div class="or">
                      
                        <div class="sign">
                            <button><ion-icon/> <span >Terms</span></button>
                            <button><ion-icon/> <span>Privacy</span></button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    </section>

    )
  }

export default Login;