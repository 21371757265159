import axios from "axios";
import { useEffect, useState } from "react";
import { useParams  } from "react-router-dom";

const Quiz =()=>{
  const [name, setName] = useState("");
  const [selectedGrades,setSelectedGrades]=useState("")
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedLessons,setSelectedLessons]=useState("")
  const [question, setQuestion] = useState("");
  const [choice1, setChoice1] = useState("");
  const [choice2, setChoice2] = useState("");
  const [choice3, setChoice3] = useState("");
  const [choice4, setChoice4] = useState("");
  const [correct_answer, setAnswer] = useState("");
  const [grade, setGrades] = useState([]);
  const [lessons, setLessons] = useState([]);
  const { subjectId} = useParams();
  const [image, setImage] = useState("");
 
 
  const fetchSubjects = async (gradeId) => {
    try {
      const res = await axios.get(`https://backend.zuruorg.site/api/v1/auth/getsubjects/${gradeId}`);
      if (res.status === 200) {
        setSubjects(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        return alert("File doesn't exist!");
      }
      let formData = new FormData();
      formData.append('file', file);

      const res = await axios.post('https://backend.zuruorg.site/api/v1/admin/img-upload', formData);
      if (res.status === 200) {
        console.log('Image URL received from backend:', res.data.imgURL); // Log image URL
        setImage(res.data.imgURL); // Set the imageURL state after successful upload
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const fetchLessons = async (gradeId, subjectId) => {
    try {
      const res = await axios.get(`https://backend.zuruorg.site/api/v1/auth/getlessons/${subjectId}`);
      if (res.status === 200) {
        setLessons(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const res = await axios.get("https://backend.zuruorg.site/api/v1/auth/getgrades");
        if (res.status === 200) {
          setGrades(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchGrades();
  }, []);
  useEffect(() => {
    if (selectedGrades) {
      fetchSubjects(selectedGrades);
    }
  }, [selectedGrades]);

  useEffect(() => {
    if (selectedGrades && selectedSubject) {
      fetchLessons(selectedGrades, selectedSubject);
    }
  }, [selectedGrades, selectedSubject]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      name,
      question,
      choice1,
      choice2,
      choice3,
      choice4,
      correct_answer,
      grade: selectedGrades,
      subject: selectedSubject,
      lesson: selectedLessons,
    };

    if (image) {
      requestData.image = image;
    }

    console.log('Request Data:', requestData); // Log request data before sending

    try {
      const res = await axios.post('https://backend.zuruorg.site/api/v1/admin/add-quiz', requestData);

      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  return(
    <form onSubmit={handleSubmit}>  
      <h2>Quiz</h2>
      <div>
        <label>Grade: </label>
        <select className="form-group col-md-6" name="grade" id="grade-select" required size={1}
value={selectedGrades}onChange={(e) => setSelectedGrades(e.target.value)}>
  <option>Please select grade</option>
              {  
                grade.map(grade=>(
                  <option value={grade._id} key={grade._id}>{grade.grade}</option>
                ))
              }
      </select>
      </div>
      <div>
  <label>Subject: </label>
  <select
    className="form-group col-md-6"
    name="subject"
    id="subject-select"
    required
    size={1}
    value={selectedSubject}
    onChange={(e) => setSelectedSubject(e.target.value)}
  >
    <option>Please select Subject</option>
    {  
      subjects.map(subject => (
        <option value={subject._id} key={subject._id}>{subject.name}</option>
      ))
    }
  </select>
</div>


<div>
  <label>Lesson: </label>
  <select
    className="form-group col-md-6"
    name="lesson"
    id="lesson-select"
    required
    size={1}
    value={selectedLessons}
    onChange={(e) => setSelectedLessons(e.target.value)}
  >        
    <option>Please select lesson</option>
    {  
      lessons.map(lesson => (
        <option value={lesson._id} key={lesson._id}>{lesson.name}</option>
      ))
    }
  </select>
</div>

      <div>
        <label>Quiz Name:</label>
        <input type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value)}}/>
      </div>
      <div>
      <label>Image: </label>
      <input type="file" onChange={handleUpload}/>
      </div>
      <div>
        <label>Question:</label>
        <input type="text" name="question" value={question} onChange={(e)=>{setQuestion(e.target.value)}}/>
      </div>
      <div>
      <label>Choice 1:</label>
        <input type="text" name="choice1" value={choice1} onChange={(e)=>{setChoice1(e.target.value)}}/>
      </div>
      <div>
      <label>Choice2:</label>
        <input type="text" name="choice2" value={choice2} onChange={(e)=>{setChoice2(e.target.value)}}/>
      </div>
      <div>
      <label>Choice3:</label>
        <input type="text" name="choice3" value={choice3} onChange={(e)=>{setChoice3(e.target.value)}}/>
      </div>
      <div>
      <label>Choice4:</label>
        <input type="text" name="choice4" value={choice4} onChange={(e)=>{setChoice4(e.target.value)}}/>
      </div>
      <div>
      <label>Correct Answer:</label>
        <input type="text" name="correct_answer" value={correct_answer} onChange={(e)=>{setAnswer(e.target.value)}}/>
      </div>
      <button type="submit">ADD Question</button>
    </form>
  );
}

export default Quiz;