import React, { useState, useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';
import axios from 'axios';
import "./home.css";

const Adddata = () => {
  
  return (
    <div>
      <h1>What do you want to do today</h1>
      <ul>
        

           <li> <Link to={`/dashboard/$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3x$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.`}>Add Grade</Link></li> 
           <li> <Link to={`/dashboard/$2a$12$mnIqUYPTlr$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuh$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.qh6Vl0lIaw2..`}>Add Subject</Link></li>
           <li> <Link to={`/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.KtNqh6Vl0lIaw2.`}>Add Lesson Video</Link></li>
           <li> <Link to={`/dashboard/$2a$12$mnIqUY$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsr$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.`}>Add Quize for lessons</Link></li>
          <li> <Link to={`/dashboard/$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.`}>Add Exam according to subject</Link> </li>
          <li><Link to={`/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3$2a$12$mnIqUYPTlrS.iyDfCwEHH..KtNqh6Vl0lIaw2.`}>Add Todays Message</Link></li>
          
          <li> <Link to={`/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHH`}>Add Targeter Exams according to subject</Link> </li>
           
           
           
         
     
      </ul>
    </div>
  );
};

export default Adddata;