import axios from "axios";
import { useEffect, useState } from "react";

const Exampdf = () => {
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [pdf, setPdf] = useState(""); // Store the PDF URL or reference
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [pdfName, setPdfName] = useState(""); // Store the name of the PDF

  // Fetch subjects based on selected grade
  const fetchSubjects = async (gradeId) => {
    try {
      const res = await axios.get(`https://backend.zuruorg.site/api/v1/auth/getsubjects/${gradeId}`);
      if (res.status === 200) {
        setSubjects(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const res = await axios.get("https://backend.zuruorg.site/api/v1/auth/getgrades");
        if (res.status === 200) {
          setGrades(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGrades();
  }, []);

  useEffect(() => {
    if (selectedGrade) {
      fetchSubjects(selectedGrade);
    }
  }, [selectedGrade]);

  // Handle PDF upload
  const handleUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        return alert("No file selected!");
      }
      if (file.type !== 'application/pdf') {
        return alert("Only PDF files are allowed!");
      }
      let formData = new FormData();
      formData.append('file', file);

      const res = await axios.post('https://backend.zuruorg.site/api/v1/admin/pdf-upload', formData);
      if (res.status === 200) {
        console.log('PDF URL received from backend:', res.data.pdfURL); // Log PDF URL
        setPdf(res.data.pdfURL); // Set the PDF URL state after successful upload
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedGrade || !selectedSubject || !pdf || !pdfName) {
      return alert("Please select grade, subject, upload a PDF, and enter a name for the PDF.");
    }

    const requestData = {
      grade: selectedGrade,
      subject: selectedSubject,
      pdfUrl: pdf, // Send the PDF URL in the request payload
      name: pdfName, // Send the name of the PDF
    };

    console.log('Request Data:', requestData); // Log request data before sending

    try {
      const res = await axios.post('https://backend.zuruorg.site/api/v1/admin/add-exampdf', requestData);
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Exam</h2>
      <div>
        <label>Grade: </label>
        <select
          className="form-group col-md-6"
          name="grade"
          id="grade-select"
          required
          size={1}
          value={selectedGrade}
          onChange={(e) => setSelectedGrade(e.target.value)}
        >
          <option value="">Please select grade</option>
          {grades.map(g => (
            <option value={g._id} key={g._id}>{g.grade}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Subject: </label>
        <select
          className="form-group col-md-6"
          name="subject"
          id="subject-select"
          required
          size={1}
          value={selectedSubject}
          onChange={(e) => setSelectedSubject(e.target.value)}
        >
          <option value="">Please select subject</option>
          {subjects.map(s => (
            <option value={s._id} key={s._id}>{s.name}</option>
          ))}
        </select>
      </div>
      <div>
        <label>PDF Name: </label>
        <input
          type="text"
          value={pdfName}
          onChange={(e) => setPdfName(e.target.value)}
          required
          placeholder="Enter the name of the PDF"
        />
      </div>
      <div>
        <label>PDF: </label>
        <input type="file" accept="application/pdf" onChange={handleUpload} />
      </div>
      <button type="submit">Upload</button>
    </form>
  );
};

export default Exampdf;
