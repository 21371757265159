import axios from "axios";
import { useEffect, useState } from "react";

const Subject = () => {
  const [subject, setSubject] = useState("");
  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState("");
  const [image, setImage] = useState("");

  // Fetch grades from backend on component mount
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const res = await axios.get('https://backend.zuruorg.site/api/v1/auth/getgrades');
        setGrades(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGrades();
  }, []);

  // Handle file upload
  const handleUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) {
        return alert("File doesn't exist!");
      }
      let formData = new FormData();
      formData.append('file', file);

      const res = await axios.post('https://backend.zuruorg.site/api/v1/admin/img-upload', formData);
      if (res.status === 200) {
        console.log('Image URL received from backend:', res.data.imgURL); // Log image URL
        setImage(res.data.imgURL); // Set the imageURL state after successful upload
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      return alert("Please upload an image before submitting!");
    }
    try {
      const res = await axios.post('https://backend.zuruorg.site/api/v1/admin/add-subject', {
        subject: subject,
        grade: grade,
        image: image // Pass the imageURL state to the backend
      });
      if (res.status === 200) {
        window.location.reload(); // Reload page on successful submission
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Subject</h2>
      <label>Subject:</label>
      <input type="text" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
      <br/>
      <label>Grade:</label>
      <select className="form-group col-md-6" name="grade" id="grade-select" required size={1} onChange={(e) => setGrade(e.target.value)}>
        <option>Please select grade</option>
        {grades.map(grade => (
          <option value={grade._id} key={grade._id}>{grade.grade}</option>
        ))}
      </select>
      <br/>
      <label>Image: </label>
      <input type="file" onChange={handleUpload} />
      <br/>
      <button type="submit">Submit</button>
    </form>
  );
};

export default Subject;
